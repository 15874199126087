import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticQuery, graphql, Link } from "gatsby"
import { useGetVideos } from '../hooks/get-videos';
import { useIsClient } from '../hooks/get-is-client';

const RelatedVideos = ({ posts }) => {
 return (
    <>
        {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.slug;
        return (
            <Col key={node.id} xs={12} md={5} xl={3} className="pt-0 mb-4">
                <Link to={`/${node.slug}`}>
                    <div className="video-thumb mb-4">
                        <img src={`${node.frontmatter.videoThumb}?${new Date().getTime()}`} className="img-fluid" alt={`${node.frontmatter.title} thumbnail`} />
                        <div className="run-time">{ node.frontmatter.runTime }</div>
                        <div className="play-icon"><span><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z" fill="white"/></svg></span></div>
                    </div>                               
                    <h4 className="mb-2">{ node.frontmatter.title }</h4>
                    <p>{ node.frontmatter.subheadline }</p>
                </Link>
            </Col>
        )}
        )}
    </>    
)};


export default (props) => {

    const related = [];

    const { isClient, key } = useIsClient();

    const { edges } = useGetVideos();
    const [videos, setVideos] = useState(edges)



    function shuffleArray(array) {
        let tempArray = array;
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return tempArray;
    }

    useEffect(() => {
        setVideos(shuffleArray(videos) )
    }, [])

    // iterate through article vertex
    for (const vertex of videos) {
        // handle base case
        if (related.length >= props.limit) {
        continue;
        }

        // abstract tags
        const {
        frontmatter: { tags },
        } = vertex.node;

        // handle case where there are no tags or prop tags
        if (!tags || !props.tags) {
        continue;
        }

        const formattedTags = tags.toString().split(",");

        // ensure tags match and article is not the same as current
        if (
        props.tags.some((item) => formattedTags.includes(item)) &&
        props.title !== vertex.node.frontmatter.title
        ) {
        related.push(vertex);
        }
    }
    if ( !isClient ) return null;
    return (
        <>
        <Row className="mt-lg-4 student-profiles justify-content-around" key={key}>
            <Col xs={12} className="mb-mb-4 pb-3">
                <p className="up-next">Up Next:</p>
            </Col>
            <RelatedVideos posts={related} />
        </Row>
        </>
    );
};

/*
export function UpNextVideos({ page }) {

    const data = useStaticQuery(graphql`
    query UpNextVideos {
            allMdx(
                filter: {frontmatter: {tags: {in: ["video"]}}}
            ) {
                nodes {
                id
                slug
                frontmatter {
                    title
                    headline
                    subheadline
                    videoThumb
                    runTime
                }
            }
        }
    }
    `)

    return (
        <Row className="mt-lg-4 student-profiles justify-content-around">
            <Col xs={12} className="mb-mb-4 pb-3">
                <p className="up-next">Up Next:</p>
            </Col>
            {
                data.allMdx.nodes.splice(Math.floor(Math.random()*data.allMdx.nodes.length), 4).map((node, index) => {
                    console.log(node)
                    return (
                        <>
                        <Col key={node.id} xs={12} md={5} xl={3} className="pt-0 mb-4">
                            <Link to={`/${node.slug}`}>
                                <div className="video-thumb mb-4">
                                    <img src={`${node.frontmatter.videoThumb}?${new Date().getTime()}`} className="img-fluid" alt={`${node.frontmatter.title} thumbnail`} />
                                    <div className="run-time">{ node.frontmatter.runTime }</div>
                                    <div className="play-icon"><span><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z" fill="white"/></svg></span></div>
                                </div>                               
                                <h4 className="mb-2">{ node.frontmatter.title }</h4>
                                <p>{ node.frontmatter.subheadline }</p>
                            </Link>
                        </Col>
                        </>
                    )
                })
            }
        </Row>
    )
} */

